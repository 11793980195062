import * as Yup from 'yup'
import { validate } from './validate'

const userCreateSchema = Yup.object({
  email: Yup.string().required().email(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  password: Yup.string().required().min(8),
  role: Yup.string().required(),
  active: Yup.boolean().required()
})

const userMassCreateSchema = Yup.object({
  email: Yup.mixed().when('isArray', {
    is: Array.isArray,
    then: Yup.array().of(Yup.string()),
    otherwise: Yup.string()
  }),
  massCustomerId: Yup.string().required('Sinun täytyy valita asiakas!')
})

const userUpdateSchema = userCreateSchema.shape({
  password: Yup.string().notRequired()
})

const superadminCreateSchema = userCreateSchema.shape({
  password: Yup.string().notRequired(),
  customerId: Yup.string().notRequired().nullable()
})

const superadminUpdateSchema = userCreateSchema.shape({
  password: Yup.string().notRequired(),
  customerId: Yup.string().notRequired().nullable()
})

export const validateMassUserCreation = (values) => {
  return validate(userMassCreateSchema)(values)
}

export const validateUserCreation = (values) => {
  if (values.role === 'superadmin') {
    return validate(superadminCreateSchema)(values)
  } else {
    if (values.createManualPassword) {
      return validate(userCreateSchema)(values)
    } else {
      return validate(userUpdateSchema)(values)
    }
  }
}

export const validateUserUpdate = (values) => {
  if (values.role === 'superadmin') {
    return validate(superadminUpdateSchema)(values)
  } else {
    return validate(userUpdateSchema)(values)
  }
}
