import omit from 'lodash/omit'
import get from 'lodash/get'
import keys from 'lodash/keys'
import isPlainObject from 'lodash/isPlainObject'
import omitDeep from 'omit-deep-lodash'

import {
  updateCustomerMutation,
  updateProjectMutation,
  updateUserMutation,
  updateQuestionMutation,
  updateStudyMutation,
  updateOwnDataMutation,
  areaSelectionUpdate
} from '../graphql/mutations'

const clean = (values) =>
  keys(omit(values, ['id', '__typename'])).reduce((map, key) => {
    const mapValue = values[key]
    if (isPlainObject(mapValue)) {
      map[key] = clean(mapValue)
    } else {
      map[key] = mapValue
    }
    return map
  }, {})

export default {
  customer: ({ id, data: values }) => ({
    query: updateCustomerMutation,
    variables: { id, values: clean(values) },
    parseResponse: (response) => ({ data: response.data.updateCustomer })
  }),
  project: ({ id, data: values }) => ({
    query: updateProjectMutation,
    variables: { id, values: clean(values) },
    parseResponse: (response) => ({ data: response.data.updateProject })
  }),
  user: ({ id, data: values }) => ({
    query: updateUserMutation,
    variables: { id, values: clean(values) },
    parseResponse: (response) => ({ data: response.data.updateUser })
  }),
  question: ({ id, data: { file, ...rest } }) => ({
    query: updateQuestionMutation,
    variables: { id, values: clean({ file: get(file, 'rawFile'), ...rest }) },
    parseResponse: (response) => ({ data: response.data.updateQuestion })
  }),
  study: ({ data: { file, ...rest } }) => ({
    query: updateStudyMutation,
    variables: {
      values: omitDeep({ file: get(file, 'rawFile'), ...rest }, '__typename')
    },
    parseResponse: (response) => ({ data: response.data.updateStudy })
  }),
  ownData: ({ data: { file, ...rest } }) => ({
    query: updateOwnDataMutation,
    variables: {
      values: omitDeep({ file: get(file, 'rawFile'), ...rest }, '__typename')
    },
    parseResponse: (response) => ({ data: response.data.updateOwnData })
  }),
  areaSelection: ({ id, data: values }) => ({
    query: areaSelectionUpdate,
    variables: { id, values: clean(values) },
    parseResponse: (response) => ({ data: response.data.areaSelectionUpdate })
  })
}
