import React, { FC } from 'react'

import BusinessIcon from '@material-ui/icons/Business'
import DateRangeIcon from '@material-ui/icons/DateRange'

import {
  List,
  Datagrid,
  TextField,
  EditButton,
  SelectField,
  FilterList,
  FilterListItem,
  TopToolbar,
  Filter,
  SearchInput,
  CreateButton
} from 'react-admin'
import { Card, CardContent, ListProps } from '@material-ui/core'
import { areaTypes, regions } from '.'
import { commonStyles } from '../../styles'

export const ToolbarActionMenu = () => {
  const classes = commonStyles()

  return (
    <TopToolbar className={classes.toolbar}>
      <Filter>
        <SearchInput source="zoneCodeSearch" placeholder="Aluekoodi" alwaysOn />
        <SearchInput
          source="postalNameSearch"
          placeholder="Aluenimi"
          alwaysOn
        />
      </Filter>
      <div>
        <CreateButton basePath="/add-user-area" label="Lisää aluevalinta" />
        <CreateButton label="Päivitä Paavo-data" />
      </div>
    </TopToolbar>
  )
}

export const FilterSidebar = () => (
  <div style={{ width: '30em', marginLeft: '3em' }}>
    <Card>
      <CardContent>
        <AreaTypeFilter />
        <RegionFilter />
        <OtherFilter />
      </CardContent>
    </Card>
  </div>
)

const AreaTypeFilter = () => (
  <FilterList label="Aluetyyppi" icon={<BusinessIcon />}>
    <FilterListItem
      label={'Postinumero'}
      value={{
        areaType: 0
      }}
    />
    <FilterListItem
      label={'Kunta'}
      value={{
        areaType: 1
      }}
    />
    <FilterListItem
      label={'Maakunta'}
      value={{
        areaType: 2
      }}
    />
    <FilterListItem
      label={'Koko maa'}
      value={{
        areaType: 3
      }}
    />
  </FilterList>
)

const OtherFilter = () => (
  <FilterList label="Muut" icon={<BusinessIcon />}>
    <FilterListItem
      label={'Ei postinumeroa'}
      value={{
        noZoneIdentifier: true
      }}
    />
    <FilterListItem
      label={'Ei kuntakoodia'}
      value={{
        noMunicipalityIdentifier: true
      }}
    />
    <FilterListItem
      label={'Ei maakuntakoodia'}
      value={{
        regionIdentifier: '0'
      }}
    />
  </FilterList>
)

const RegionFilter = () => (
  <FilterList label="Maakunta" icon={<DateRangeIcon />}>
    {regions.map((region) => {
      const { id: regionCode, name: regionName } = region
      return (
        <FilterListItem
          label={regionName}
          key={`filter_${regionCode}`}
          value={{
            regionIdentifier: regionCode
          }}
        />
      )
    })}
  </FilterList>
)

export const AreaList: FC<ListProps> = (props) => (
  <List
    {...props}
    bulkActionButtons={false}
    perPage={25}
    actions={<ToolbarActionMenu {...props} />}
    aside={<FilterSidebar />}
  >
    <Datagrid rowClick="edit">
      <TextField source="areaDescription" label="Alue" />
      <SelectField source="areaType" choices={areaTypes} label="Aluetyyppi" />
      <SelectField
        source="regionIdentifier"
        choices={regions}
        label="Maakunta"
      />
      <TextField source="municipalityIdentifier" label="Kuntakoodi" />
      <TextField source="zoneIdentifier" label="Postinumero" />
      <EditButton />
    </Datagrid>
  </List>
)
