import {
  customerQuery,
  projectQuery,
  userQuery,
  questionQuery,
  studyQuery,
  ownDataQuery,
  areaPopulationQuery,
  areaSelectionQuery
} from '../graphql/queries'

export default {
  customer: ({ id }) => ({
    query: customerQuery,
    variables: { id },
    parseResponse: (response) => ({ data: response.data.customer })
  }),
  project: ({ id }) => ({
    query: projectQuery,
    variables: { id },
    parseResponse: (response) => ({ data: response.data.project })
  }),
  user: ({ id }) => ({
    query: userQuery,
    variables: { id },
    parseResponse: (response) => ({ data: response.data.user })
  }),
  question: ({ id }) => ({
    query: questionQuery,
    variables: { id },
    parseResponse: (response) => ({ data: response.data.question })
  }),
  study: ({ id }) => ({
    query: studyQuery,
    variables: { id },
    parseResponse: (response) => ({ data: response.data.study })
  }),
  ownData: ({ id }) => ({
    query: ownDataQuery,
    variables: { id },
    parseResponse: (response) => ({ data: response.data.ownData })
  }),
  areaPopulation: ({ id }) => ({
    query: areaPopulationQuery,
    variables: { id },
    parseResponse: (response) => ({ data: response.data.areaPopulation })
  }),
  areaSelection: ({ id }) => ({
    query: areaSelectionQuery,
    variables: { id },
    parseResponse: (response) => ({ data: response.data.areaSelection })
  })
}
