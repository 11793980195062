import set from 'lodash/set'

export const validate = (schema) => (values) => {
  try {
    schema.validateSync(values)
    return {}
  } catch (err) {
    return set({}, err.path, err.errors[0])
  }
}
