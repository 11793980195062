import React, { FC } from 'react'

import {
  SimpleForm,
  TextInput,
  NumberInput,
  Edit,
  EditProps,
  ToolbarProps,
  SelectField
} from 'react-admin'
import { areaTypes, regions } from '.'

import { CustomToolbar } from '../CustomToolbar'
// @ts-ignore
const EditToolbar: FC<ToolbarProps> = (props) => (
  <CustomToolbar {...props}></CustomToolbar>
)

export const AreaEdit: FC<EditProps> = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<EditToolbar />}>
        <SelectField label="Aluetyyppi" source="areaType" choices={areaTypes} />
        <SelectField
          label="Maakunta"
          source="regionIdentifier"
          choices={regions}
        />
        <TextInput source="areaDescription" />
        <TextInput source="municipalityIdentifier" />
        <TextInput source="zoneIdentifier" />
        <NumberInput source="coordinateX" />
        <NumberInput source="coordinateY" />
        <NumberInput source="population" />
        <NumberInput source="gender_f" />
        <NumberInput source="gender_m" />
        <TextInput source="faverage" />
        <NumberInput source="income_class_low" />
        <NumberInput source="income_class_middle" />
        <NumberInput source="income_class_high" />
        <NumberInput source="income_median" />
        <NumberInput source="education_basic" />
        <NumberInput source="education_matriculation" />
        <NumberInput source="education_vocational" />
        <NumberInput source="education_bachelor" />
        <NumberInput source="education_master" />
        <NumberInput source="education_total" />
        <NumberInput source="age_average" />
        <NumberInput source="age_0_2" />
        <NumberInput source="age_3_6" />
        <NumberInput source="age_7_12" />
        <NumberInput source="age_13_15" />
        <NumberInput source="age_16_17" />
        <NumberInput source="age_18_19" />
        <NumberInput source="age_20_24" />
        <NumberInput source="age_25_29" />
        <NumberInput source="age_30_34" />
        <NumberInput source="age_35_39" />
        <NumberInput source="age_40_44" />
        <NumberInput source="age_45_49" />
        <NumberInput source="age_50_54" />
        <NumberInput source="age_55_59" />
        <NumberInput source="age_60_64" />
        <NumberInput source="age_65_69" />
        <NumberInput source="age_70_74" />
        <NumberInput source="age_75_79" />
        <NumberInput source="age_80_84" />
        <NumberInput source="age_18_plus_ko" />
        <NumberInput source="age_18_plus_hr" />
        <NumberInput source="age_0_6" />
        <NumberInput source="age_7_17" />
        <NumberInput source="age_0_17" />
        <NumberInput source="age_18_plus" />
        <NumberInput source="age_18_24" />
        <NumberInput source="age_25_34" />
        <NumberInput source="age_35_44" />
        <NumberInput source="age_45_54" />
        <NumberInput source="age_55_64" />
        <NumberInput source="age_65_74" />
        <NumberInput source="age_75_84" />
        <NumberInput source="age_85_plus" />
        <NumberInput source="household_owned" />
        <NumberInput source="household_rented" />
        <NumberInput source="summer_cottages" />
        <NumberInput source="home_small_house" />
        <NumberInput source="home_apartment" />
        <NumberInput source="workforce" />
        <NumberInput source="employed" />
        <NumberInput source="unemployed" />
        <NumberInput source="student" />
        <NumberInput source="other" />
        <NumberInput source="job_total" />
        <NumberInput source="job_primary_sector" />
        <NumberInput source="job_secondary_sector" />
        <NumberInput source="job_services" />
        <NumberInput source="job_industry_class_A" />
        <NumberInput source="job_industry_class_B" />
        <NumberInput source="job_industry_class_C" />
        <NumberInput source="job_industry_class_D" />
        <NumberInput source="job_industry_class_E" />
        <NumberInput source="job_industry_class_F" />
        <NumberInput source="job_industry_class_G" />
        <NumberInput source="job_industry_class_H" />
        <NumberInput source="job_industry_class_I" />
        <NumberInput source="job_industry_class_J" />
        <NumberInput source="job_industry_class_K" />
        <NumberInput source="job_industry_class_L" />
        <NumberInput source="job_industry_class_M" />
        <NumberInput source="job_industry_class_N" />
        <NumberInput source="job_industry_class_O" />
        <NumberInput source="job_industry_class_P" />
        <NumberInput source="job_industry_class_Q" />
        <NumberInput source="job_industry_class_R" />
        <NumberInput source="job_industry_class_S" />
        <NumberInput source="job_industry_class_T" />
        <NumberInput source="job_industry_class_U" />
        <NumberInput source="job_industry_class_X" />
        <NumberInput source="household_total_te" />
        <NumberInput source="household_size_average" />
        <NumberInput source="household_young_single" />
        <NumberInput source="household_young_childfree" />
        <NumberInput source="household_with_children" />
        <NumberInput source="household_with_children_young" />
        <NumberInput source="household_with_children_under_school_age" />
        <NumberInput source="household_with_children_school_age" />
        <NumberInput source="household_with_children_teenager" />
        <NumberInput source="household_with_adults" />
        <NumberInput source="household_with_pensioner" />
        <NumberInput source="household_single" />
        <NumberInput source="household_with_single_parent" />
        <NumberInput source="household_young" />
        <NumberInput source="household_other_mode" />
        <NumberInput source="household_total_tr" />
        <NumberInput source="household_income_average" />
        <NumberInput source="household_income_median" />
        <NumberInput source="household_income_class_low" />
        <NumberInput source="household_income_class_middle" />
        <NumberInput source="household_income_class_high" />
        <NumberInput source="household_purchasing_power" />
        <NumberInput source="home_total" />
        <NumberInput source="home_area_average" />
        <NumberInput source="buildings" />
        <NumberInput source="other_buildings" />
        <NumberInput source="residential_buildings" />
        <NumberInput source="living_space" />
        <NumberInput source="purchasing_power" />
      </SimpleForm>
    </Edit>
  )
}
