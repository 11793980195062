import React, { useState, useEffect } from 'react'
import { createElement } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from '@material-ui/core'
import { MenuItemLink, getResources, usePermissions } from 'react-admin'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'

const ADMIN_RESOURCES = ['user', 'project']

export const Menu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const open = useSelector((state) => state.admin.ui.sidebarOpen)
  const resources = useSelector(getResources)
  const permissions = usePermissions()
  const [isSuperadmin, setIsSuperadmin] = useState(false)

  useEffect(() => {
    const role = get(permissions, 'permissions.role')
    setIsSuperadmin(role === 'superadmin')
  }, [permissions])

  return (
    <div style={{ marginTop: '2vh' }}>
      {resources.map((resource) => {
        if (!isSuperadmin && !ADMIN_RESOURCES.includes(resource.name)) {
          return null
        }
        return (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={
              (resource.options && resource.options.label) || resource.name
            }
            leftIcon={createElement(resource.icon)}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        )
      })}
      {isXSmall && logout}
    </div>
  )
}

export default withRouter(Menu)
