import React from 'react'
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  Edit,
  BooleanInput,
  SelectArrayInput,
  ReferenceArrayInput,
  EditProps,
  DateField
} from 'react-admin'

import { validate } from '../../../schema/validate'
import { PublicInput, schema, UserReference } from './publicInput'
import { CustomToolbar } from '../CustomToolbar'

export const ProjectEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm validate={validate(schema)} toolbar={<CustomToolbar />}>
        <TextInput label="Nimi" source="name" />
        <TextInput label="Kuvaus" multiline source="description" />
        <DateField label="Luotu" locales={'fi-FI'} source="createdOn" />
        <BooleanInput label="Aktiivinen" source="active" />
        <PublicInput source="public" defaultValue={false} />
        {props.permissions.role === 'superadmin' ? (
          <>
            <ReferenceInput
              source="customerId"
              reference="customer"
              label="Asiakas"
              sort={{ field: 'name', order: 'ASC' }}
              perPage={500}
            >
              <SelectInput fullWidth optionText="name" />
            </ReferenceInput>
            <ReferenceArrayInput
              label="Tutkimukset"
              source="studyIds"
              reference="study"
              sort={{ field: 'name', order: 'ASC' }}
              perPage={500}
            >
              <SelectArrayInput fullWidth optionText="name" />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              label="Omadatat"
              source="ownDataIds"
              reference="ownData"
              sort={{ field: 'name', order: 'ASC' }}
              defaultValue={[]}
              perPage={500}
            >
              <SelectArrayInput optionText="name" fullWidth />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              label="Kysymystutkimukset"
              source="questionStudyIds"
              reference="study"
              sort={{ field: 'name', order: 'ASC' }}
              defaultValue={[]}
              perPage={500}
            >
              <SelectArrayInput fullWidth optionText="name" />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              label="Aluevalinnat"
              source="areaSelectionIds"
              reference="areaSelection"
              sort={{ field: 'name', order: 'ASC' }}
              defaultValue={[]}
              perPage={500}
            >
              <SelectArrayInput fullWidth optionText="name" />
            </ReferenceArrayInput>
          </>
        ) : (
          <></>
        )}
        <UserReference label="Käyttäjät" source="userIds" reference="user" />
      </SimpleForm>
    </Edit>
  )
}
