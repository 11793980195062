import { AuthProvider } from 'react-admin'
import { loginMutation, logoutMutation } from '../graphql/mutations'
import { currentUserQuery } from '../graphql/queries'
import apolloClient from './data/apollo-client'

export const authProvider: AuthProvider = {
  login: async (logins) => {
    const { username, password } = logins
    try {
      await apolloClient.mutate({
        mutation: loginMutation,
        variables: { email: username, password: password }
      })
      const {
        data: { currentUser }
      } = await apolloClient.query({ query: currentUserQuery })
      if (
        !currentUser ||
        (currentUser.role !== 'superadmin' && currentUser.role !== 'admin')
      ) {
        console.error('Not authenticated!')
        return Promise.reject()
      }
      localStorage.setItem('permissions', currentUser.role)
      localStorage.setItem('customerId', currentUser.customerId)
      return Promise.resolve()
    } catch (err) {
      return Promise.reject()
    }
  },

  logout: async () => {
    try {
      await apolloClient.mutate({ mutation: logoutMutation })
    } catch (err) {
      console.error(err)
    }
    localStorage.removeItem('permissions')
    localStorage.removeItem('customerId')
    return Promise.resolve()
  },

  checkAuth: async () => {
    try {
      const {
        data: { currentUser }
      } = await apolloClient.query({ query: currentUserQuery })
      if (
        !currentUser ||
        (currentUser.role !== 'superadmin' && currentUser.role !== 'admin')
      ) {
        console.error('Not authenticated!')
        return Promise.reject()
      }
      localStorage.setItem('permissions', currentUser.role)
      localStorage.setItem('customerId', currentUser.customerId)
      return Promise.resolve()
    } catch (err) {
      return Promise.reject()
    }
  },

  getPermissions: async () => {
    const role = localStorage.getItem('permissions')
    const customerId = localStorage.getItem('customerId')
    if (!role || !customerId) {
      const {
        data: { currentUser }
      } = await apolloClient.query({ query: currentUserQuery })
      if (
        !currentUser ||
        (currentUser.role !== 'superadmin' && currentUser.role !== 'admin')
      ) {
        console.error('Not authenticated!')
        return Promise.reject()
      } else {
        return Promise.resolve({
          role: currentUser.role,
          customerId: currentUser.customerId
        })
      }
    }
    return role
      ? Promise.resolve({ role: role, customerId: customerId })
      : Promise.reject()
  },

  getIdentity: () => Promise.reject(),
  checkError: () => Promise.resolve()
}
