import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceField,
  BooleanField,
  DateField,
  ListProps
} from 'react-admin'

export const ProjectList: React.FC<ListProps> = (props) =>
  props.permissions.role === 'superadmin' ? (
    <List {...props} bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField source="description" />
        <DateField label="Luotu" locales={'fi-FI'} source="createdOn" />
        <BooleanField source="active" />
        <BooleanField source="public" />
        <ReferenceField
          label="Customer"
          source="customerId"
          reference="customer"
        >
          <TextField source="name" />
        </ReferenceField>

        <EditButton />
      </Datagrid>
    </List>
  ) : (
    <List {...props} bulkActionButtons={false} actions={<></>}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField source="description" />
        <DateField label="Luotu" locales={'fi-FI'} source="createdOn" />
        <BooleanField source="active" />
        <BooleanField source="public" />
        <EditButton />
      </Datagrid>
    </List>
  )
