import React from 'react'
import { List, Datagrid, TextField, ListProps, BooleanField } from 'react-admin'

export const AreaSelectionList: React.FC<ListProps> = (props) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField label="Nimi" source="name" />
      <TextField label="Kuvaus" source="description" />
      <BooleanField label="Julkinen" source="common" />
    </Datagrid>
  </List>
)
