import React, { useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import {
  Title,
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  useNotify,
  useRefresh,
  useRedirect,
  TextField,
  Toolbar,
  SaveButton
} from 'react-admin'
import { validateMassUserCreation } from '../../schema/user'
import { findEmailAddressesFromString } from '../../utils/parse_emails'

export const UserMassCreate = (props) => {
  const [emailAddresses, setFoundEmailAddresses] = useState([])
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()

  function findEmails(emailString) {
    setFoundEmailAddresses(findEmailAddressesFromString(emailString))
  }

  function emailStringToList(data) {
    const emailList = findEmailAddressesFromString(data.emailList)
    data.emailList = emailList
    return data
  }

  const PostCreateToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton
        onSuccess={saveSuccess}
        label="Luo uudet käyttäjät"
        transform={(data) => emailStringToList(data)}
        submitOnEnter={false}
      />
    </Toolbar>
  )

  const saveSuccess = ({ data }) => {
    notify(`${data.emailList.length} uutta tunnusta luotu.`)
    redirect('/user')
    refresh()
  }

  return (
    <Card>
      <Title title="Luo massakäyttäjiä - " />
      <CardContent>
        <Create {...props} basePath="massUsers">
          <SimpleForm
            validate={(values) => validateMassUserCreation(values)}
            toolbar={<PostCreateToolbar />}
          >
            <ReferenceInput
              source="massCustomerId"
              reference="customer"
              label="Customer"
              perPage={500}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput
              rows={10}
              label="Kopioi tähän sähköpostiosoitteet"
              onChange={(event) => findEmails(event.target.value)}
              multiline
              source="emailList"
            />
            <TextField label="Tunnistettuihin sähköpostiosoitteisiin lähetetään sähköposti, jossa käyttäjää pyydetään rekisteröitymään. Käyttäjä täyttää itse tietonsa, kuten etunimen ja sukunimen, sekä valitsee salasanansa." />
          </SimpleForm>
        </Create>
        <h3
          style={{ marginTop: '2em' }}
        >{`Tunnistetut sähköpostiosoitteet: ${emailAddresses.length} kpl`}</h3>
        <div>
          {emailAddresses.map((email) => {
            return <h4 key={email}>{`${email}`}</h4>
          })}
        </div>
      </CardContent>
    </Card>
  )
}
