const requireStringEnv = (env: string): string => {
  const envVariable = process.env[env]

  if (!envVariable) {
    throw new Error(`Environment variable ${env} is missing!`)
  }

  if (typeof envVariable !== 'string') {
    throw new Error(
      `Environment variable ${env} was of type ${typeof envVariable}, when string is required!`
    )
  }
  return envVariable
}

export const API_URL = requireStringEnv('REACT_APP_API')
export const SERVICE_URL = requireStringEnv('REACT_APP_SERVICE_URL')
