import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Create,
  SimpleForm,
  FileInput,
  FileField,
  TextInput,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  Edit,
  FormDataConsumer,
  Filter,
  CreateProps,
  TextInputProps,
  EditProps,
  ListProps
} from 'react-admin'
import { validateUpdate, validateCreate } from '../../schema/question'
import { CustomToolbar } from './CustomToolbar'
import React from 'react'

const ScaleInput = (props: TextInputProps) => (
  <FormDataConsumer>
    {({ formData, ...rest }) =>
      formData &&
      (formData.type === 'number_interval' ||
        formData.type === 'number_interval_with_respondent_id') ? (
        <>
          <TextInput {...props} {...rest} />
        </>
      ) : (
        <>
          <TextInput disabled {...props} {...rest} />
        </>
      )
    }
  </FormDataConsumer>
)

export const QuestionCreate = (props: CreateProps) => {
  return (
    <Create {...props}>
      <SimpleForm validate={validateCreate}>
        <TextInput source="label" />
        <SelectInput
          source="type"
          choices={[
            { id: 'number_interval', name: 'Number Interval' },
            {
              id: 'number_interval_with_respondent_id',
              name: 'Number Interval With Respondent Id'
            },
            { id: 'two_options', name: 'Two Options' }
          ]}
        />
        <ScaleInput label="Scale start value" source="scale.start" />
        <ScaleInput label="Scale end value" source="scale.end" />
        <ScaleInput label="Scale start label" source="scale.startLabel" />
        <ScaleInput label="Scale end label" source="scale.endLabel" />

        <ReferenceInput source="studyId" reference="study" perPage={500}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <FileInput accept="text/csv" source="file" label="CSV file">
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  )
}

export const QuestionEdit = (props: EditProps) => {
  return (
    <Edit {...props}>
      <SimpleForm validate={validateUpdate} toolbar={<CustomToolbar />}>
        <TextInput source="label" />
        <SelectInput
          source="type"
          choices={[
            { id: 'number_interval', name: 'Number Interval' },
            { id: 'two_options', name: 'Two Options' }
          ]}
        />
        <ScaleInput label="Scale start value" source="scale.start" />
        <ScaleInput label="Scale end value" source="scale.end" />
        <ScaleInput label="Scale start label" source="scale.startLabel" />
        <ScaleInput label="Scale end label" source="scale.endLabel" />
        <ReferenceInput source="studyId" reference="study" perPage={500}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <FileInput accept="text/csv" source="file" label="CSV file">
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Edit>
  )
}

const QuestionFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="search" alwaysOn />
    <ReferenceInput
      label="Study"
      source="studyId"
      reference="study"
      allowEmpty
      perPage={500}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
)

export const QuestionList = (props: ListProps) => (
  <List filters={<QuestionFilter />} {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="label" />
      <TextField source="type" />
      <ReferenceField label="Study" source="studyId" reference="study">
        <TextField source="name" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
)
