import gql from 'graphql-tag'
import {
  customerFragment,
  projectFragment,
  userFragment,
  questionFragment,
  studyFragment,
  ownDataFragment
} from './fragments'

export const createCustomerMutation = gql`
  mutation ($values: CustomerInput) {
    createCustomer(values: $values) {
      ...Customer
    }
  }
  ${customerFragment}
`

export const createProjectMutation = gql`
  mutation ($values: ProjectInput!) {
    createProject(values: $values) {
      ...Project
    }
  }
  ${projectFragment}
`

export const createMassUsersMutation = gql`
  mutation ($values: CreateMassUserInput) {
    createMassUsers(values: $values) {
      status
      emailList
      id
    }
  }
`

export const createUserMutation = gql`
  mutation ($values: CreateUserInput!) {
    createUser(values: $values) {
      ...User
    }
  }
  ${userFragment}
`

export const updateCustomerMutation = gql`
  mutation ($id: ID!, $values: CustomerInput) {
    updateCustomer(id: $id, values: $values) {
      ...Customer
    }
  }
  ${customerFragment}
`

export const updateProjectMutation = gql`
  mutation ($id: ID!, $values: UpdateProject!) {
    updateProject(id: $id, values: $values) {
      ...Project
    }
  }
  ${projectFragment}
`

export const updateUserMutation = gql`
  mutation ($id: ID!, $values: UpdateUserInput!) {
    updateUser(id: $id, values: $values) {
      ...User
    }
  }
  ${userFragment}
`

export const removeCustomerMutation = gql`
  mutation ($id: ID!) {
    deleteCustomer(id: $id) {
      ...Customer
    }
  }
  ${customerFragment}
`

export const removeProjectMutation = gql`
  mutation ($id: ID!) {
    deleteProject(id: $id) {
      ...Project
    }
  }
  ${projectFragment}
`

export const removeUserMutation = gql`
  mutation ($id: ID!) {
    deleteUser(id: $id) {
      ...User
    }
  }
  ${userFragment}
`

export const loginMutation = gql`
  mutation ($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      status
      token
    }
  }
`

export const logoutMutation = gql`
  mutation {
    logout
  }
`

export const createQuestionMutation = gql`
  mutation ($values: CreateQuestionInput!) {
    createQuestion(values: $values) {
      ...Question
    }
  }
  ${questionFragment}
`

export const updateQuestionMutation = gql`
  mutation ($id: ID!, $values: UpdateQuestionInput!) {
    updateQuestion(id: $id, values: $values) {
      ...Question
    }
  }
  ${questionFragment}
`

export const removeQuestionMutation = gql`
  mutation ($id: ID!) {
    deleteQuestion(id: $id) {
      ...Question
    }
  }
  ${questionFragment}
`

export const createStudyMutation = gql`
  mutation ($values: CreateStudyInput!) {
    createStudy(values: $values) {
      ...Study
    }
  }
  ${studyFragment}
`

export const updateStudyMutation = gql`
  mutation ($values: UpdateStudyInput!) {
    updateStudy(values: $values) {
      ...Study
    }
  }
  ${studyFragment}
`

export const removeStudyMutation = gql`
  mutation ($id: ID!) {
    deleteStudy(id: $id) {
      ...Study
    }
  }
  ${studyFragment}
`

export const createOwnDataMutation = gql`
  mutation ($values: CreateOwnDataInput!) {
    createOwnData(values: $values) {
      ...OwnData
    }
  }
  ${ownDataFragment}
`

export const updateOwnDataMutation = gql`
  mutation ($values: UpdateOwnDataInput!) {
    updateOwnData(values: $values) {
      ...OwnData
    }
  }
  ${ownDataFragment}
`

export const removeOwnDataMutation = gql`
  mutation ($id: ID!) {
    deleteOwnData(id: $id) {
      ...OwnData
    }
  }
  ${ownDataFragment}
`

export const areaPopulationMutation = gql`
  mutation ($values: UpdatePaavoDataInput!) {
    updatePaavoData(values: $values) {
      id
      status
      updateCount
    }
  }
`

export const areaSelectionCreate = gql`
  mutation ($values: AreaSelectionCreate!) {
    areaSelectionCreate(values: $values) {
      id
      name
      description
    }
  }
`

export const areaSelectionUpdate = gql`
  mutation ($id: ID!, $values: AreaSelectionUpdate!) {
    areaSelectionUpdate(id: $id, values: $values) {
      id
      name
      description
      common
      regions
      municipalities
      postals
    }
  }
`

export const areaSelectionDelete = gql`
  mutation ($id: ID!) {
    areaSelectionDelete(id: $id) {
      name
      description
      common
    }
  }
`
