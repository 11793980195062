import { ApolloClient, InMemoryCache } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { API_URL } from '../../config'

const httpLink = new createUploadLink({
  uri: `${API_URL}`,
  credentials: 'include',
  headers: { 'apollo-require-preflight': true }
})

const defaultOptions = {
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
}

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
  defaultOptions
})

export default client
