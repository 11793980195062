import get from 'lodash/get'

import {
  createCustomerMutation,
  createProjectMutation,
  createUserMutation,
  createMassUsersMutation,
  createQuestionMutation,
  createStudyMutation,
  createOwnDataMutation,
  areaPopulationMutation,
  areaSelectionCreate
} from '../graphql/mutations'

export default {
  customer: ({ data: values }) => ({
    query: createCustomerMutation,
    variables: { values },
    parseResponse: (response) => ({ data: response.data.createCustomer })
  }),
  project: ({ data: values }) => {
    return {
      query: createProjectMutation,
      variables: { values },
      parseResponse: (response) => ({ data: response.data.createProject })
    }
  },
  user: ({ data: values }) => ({
    query: createUserMutation,
    variables: { values },
    parseResponse: (response) => ({ data: response.data.createUser })
  }),
  massUsers: ({ data: values }) => ({
    query: createMassUsersMutation,
    variables: { values },
    parseResponse: (response) => ({ data: response.data.createMassUsers })
  }),
  study: ({ data: { file, ...rest } }) => ({
    query: createStudyMutation,
    variables: { values: { file: get(file, 'rawFile'), ...rest } },
    parseResponse: (response) => ({ data: response.data.createStudy })
  }),
  question: ({ data: { file, ...rest } }) => ({
    query: createQuestionMutation,
    variables: { values: { file: get(file, 'rawFile'), ...rest } },
    parseResponse: (response) => ({ data: response.data.createQuestion })
  }),
  ownData: ({ data: { file, ...rest } }) => ({
    query: createOwnDataMutation,
    variables: { values: { file: get(file, 'rawFile'), ...rest } },
    parseResponse: (response) => ({ data: response.data.createOwnData })
  }),
  areaPopulation: ({ data: { file, ...rest } }) => ({
    query: areaPopulationMutation,
    variables: { values: { file: get(file, 'rawFile'), ...rest } },
    parseResponse: (response) => ({ data: response.data.updatePaavoData })
  }),
  areaSelection: ({ data: { file, ...rest } }) => ({
    query: areaSelectionCreate,
    variables: { values: { file: get(file, 'rawFile'), ...rest } },
    parseResponse: (response) => ({ data: response.data.areaSelectionCreate })
  })
}
