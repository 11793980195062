import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import MenuIcon from '@material-ui/icons/Menu'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import logo from './logo.png'
import { useLogout } from 'react-admin'
import { SERVICE_URL } from '../../config'

const useStyles = makeStyles({
  title: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginLeft: '4em'
  },
  textTitle: {
    flex: 1
  },
  menuIcon: {
    cursor: 'pointer'
  },
  hideAnchorStyles: {
    textDecoration: 'none',
    color: 'black'
  },
  imgStyle: {
    height: '30px'
  },
  logoTitle: {}
})

const NavigationBar = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles()
  const logout = useLogout()

  const logoImg = (
    <img src={logo} alt="Insight360 logo" className={classes.imgStyle} />
  )

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <AppBar {...props} className={classes.title} color="secondary">
      <Toolbar className={classes.spacer}>
        {logoImg}
        <Typography variant="h6" className={classes.textTitle}>
          Hallintapaneeli
        </Typography>
        <Typography
          variant="subtitle1"
          id="react-admin-title"
          className={classes.textTitle}
        />
        <MenuIcon
          className={classes.menuIcon}
          onClick={handleClick}
          aria-controls="simple-menu"
        />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <a className={classes.hideAnchorStyles} href={`${SERVICE_URL}`}>
            <MenuItem>Takaisin sovellukseen</MenuItem>
          </a>
          <MenuItem onClick={() => logout()}>Kirjaudu ulos</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}

export default NavigationBar
