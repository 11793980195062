import gql from 'graphql-tag'

export const customerFragment = gql`
  fragment Customer on Customer {
    id
    name
    description
    createdOn
    active
    two_factor_authentication
  }
`

export const studyFragment = gql`
  fragment Study on Study {
    id
    name
    description
    createdOn
    uploadedBy
    startDate
    endDate
    allowExport
    segments {
      id
      name
    }
  }
`

export const ownDataFragment = gql`
  fragment OwnData on OwnData {
    id
    name
    description
    createdOn
    uploadedBy
    startDate
    endDate
    allowExport
    segments {
      id
      name
    }
  }
`

export const projectFragment = gql`
  fragment Project on Project {
    id
    name
    description
    active
    public
    createdOn
    customerId
    studyIds
    userIds
    questionStudyIds
    ownDataIds
    areaSelectionIds
  }
`

export const userFragment = gql`
  fragment User on User {
    id
    email
    firstName
    lastName
    role
    active
    customerId
    createdOn
  }
`

export const questionFragment = gql`
  fragment Question on Question {
    id
    label
    type
    studyId
    uploadedBy
    scale {
      start
      end
      startLabel
      endLabel
    }
  }
`

export const areaPopulationFragment = gql`
  fragment Area on Area {
    id
    areaType
    municipalityIdentifier
    regionIdentifier
    zoneIdentifier
    areaDescription
    coordinateX
    coordinateY
    population
    gender_f
    gender_m
    faverage
    income_class_low
    income_class_middle
    income_class_high
    income_median
    education_basic
    education_matriculation
    education_vocational
    education_bachelor
    education_master
    education_total
    age_average
    age_0_2
    age_3_6
    age_7_12
    age_13_15
    age_16_17
    age_18_19
    age_20_24
    age_25_29
    age_30_34
    age_35_39
    age_40_44
    age_45_49
    age_50_54
    age_55_59
    age_60_64
    age_65_69
    age_70_74
    age_75_79
    age_80_84
    age_18_plus_ko
    age_18_plus_hr
    age_0_6
    age_7_17
    age_0_17
    age_18_plus
    age_18_24
    age_25_34
    age_35_44
    age_45_54
    age_55_64
    age_65_74
    age_75_84
    age_85_plus
    household_owned
    household_rented
    summer_cottages
    home_small_house
    home_apartment
    workforce
    employed
    unemployed
    student
    other
    job_total
    job_primary_sector
    job_secondary_sector
    job_services
    job_industry_class_A
    job_industry_class_B
    job_industry_class_C
    job_industry_class_D
    job_industry_class_E
    job_industry_class_F
    job_industry_class_G
    job_industry_class_H
    job_industry_class_I
    job_industry_class_J
    job_industry_class_K
    job_industry_class_L
    job_industry_class_M
    job_industry_class_N
    job_industry_class_O
    job_industry_class_P
    job_industry_class_Q
    job_industry_class_R
    job_industry_class_S
    job_industry_class_T
    job_industry_class_U
    job_industry_class_X
    household_total_te
    household_size_average
    household_young_single
    household_young_childfree
    household_with_children
    household_with_children_young
    household_with_children_under_school_age
    household_with_children_school_age
    household_with_children_teenager
    household_with_adults
    household_with_pensioner
    household_single
    household_with_single_parent
    household_young
    household_other_mode
    household_total_tr
    household_income_average
    household_income_median
    household_income_class_low
    household_income_class_middle
    household_income_class_high
    household_purchasing_power
    home_total
    home_area_average
    buildings
    other_buildings
    residential_buildings
    living_space
    purchasing_power
  }
`

export default {
  project: projectFragment,
  customer: customerFragment,
  study: studyFragment,
  question: questionFragment,
  ownData: ownDataFragment,
  areaPopulation: areaPopulationFragment
}
