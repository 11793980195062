import React, { FC } from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  BooleanInput,
  ReferenceInput,
  EditProps,
  DateField
} from 'react-admin'

import { validateUserUpdate } from '../../../schema/user'
import { rolesSuperadmin, roles } from '.'
import { CustomToolbar } from '../CustomToolbar'

export const UserEdit: FC<EditProps> = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm validate={validateUserUpdate} toolbar={<CustomToolbar />}>
        <TextInput source="firstName" />
        <TextInput source="lastName" />
        <TextInput source="email" />
        <TextInput source="password" type="password" />
        <SelectInput
          source="role"
          choices={
            props.permissions.role === 'superadmin' ? rolesSuperadmin : roles
          }
          defaultValue="user"
        />
        {props.permissions.role === 'superadmin' ? (
          <ReferenceInput
            source="customerId"
            reference="customer"
            label="Customer"
            perPage={500}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        ) : (
          <></>
        )}
        <BooleanInput source="active" />
        <DateField source="createdOn" />
      </SimpleForm>
    </Edit>
  )
}
