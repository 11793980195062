import * as React from 'react'
import { Route } from 'react-router-dom'
import { UserMassCreate } from '../views/usermass'

const removeStaticContext = (props) => {
  // This is a prop passed by react-router
  // React will throw a warning if this is passed as props to RA-create
  if ('staticContext' in props) {
    delete props.staticContext
  }
  return props
}

export default [
  <Route
    path="/massUsers"
    key="massUsersRoute"
    render={(props) => {
      return (
        <UserMassCreate
          {...removeStaticContext(props)}
          resource={'massUsers'}
          record={{ emailList: '', massCustomerId: '' }}
        />
      )
    }}
  />
]
