import { AreaEdit } from './Edit'
import { AreaUpdate } from './Create'
import { AreaList } from './List'

const areaTypes = [
  { id: 0, name: 'Postinumero' },
  { id: 1, name: 'Kunta' },
  { id: 2, name: 'Maakunta' },
  { id: 3, name: 'Koko Maa' }
]

const regions = [
  { id: '01', name: 'Uusimaa' },
  { id: '02', name: 'Varsinais-Suomi' },
  { id: '04', name: 'Satakunta' },
  { id: '05', name: 'Kanta-Häme' },
  { id: '06', name: 'Pirkanmaa' },
  { id: '07', name: 'Päijät-Häme' },
  { id: '08', name: 'Kymenlaakso' },
  { id: '09', name: 'Etelä-Karjala' },
  { id: '10', name: 'Etelä-Savo' },
  { id: '11', name: 'Pohjois-Savo' },
  { id: '12', name: 'Pohjois-Karjala' },
  { id: '13', name: 'Keski-Suomi' },
  { id: '14', name: 'Etelä-Pohjanmaa' },
  { id: '15', name: 'Pohjanmaa' },
  { id: '16', name: 'Keski-Pohjanmaa' },
  { id: '17', name: 'Pohjois-Pohjanmaa' },
  { id: '18', name: 'Kainuu' },
  { id: '19', name: 'Lappi' },
  { id: '21', name: 'Ahvenanmaa' }
]

export { AreaEdit, AreaUpdate, AreaList, areaTypes, regions }
