import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Create,
  SimpleForm,
  FileInput,
  FileField,
  TextInput,
  Edit,
  CreateProps,
  EditProps,
  ListProps,
  ArrayInput,
  SimpleFormIterator,
  DateField,
  ReferenceField,
  DateInput,
  TabbedForm,
  FormTab,
  BooleanInput
} from 'react-admin'
import * as Yup from 'yup'
import { validate } from '../../schema/validate'

import { CustomToolbar } from './CustomToolbar'

const createSchema = Yup.object().shape({
  id: Yup.string().required(),
  name: Yup.string().required(),
  description: Yup.string().nullable(),
  allowExport: Yup.boolean(),
  file: Yup.object().required()
})

const updateSchema = createSchema.shape({
  file: Yup.object().nullable()
})

export const OwnDataCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props}>
      <SimpleForm validate={validate(createSchema)}>
        <TextInput source="id" />
        <TextInput source="name" />
        <TextInput source="description" />
        <DateInput source="startDate" />
        <DateInput source="endDate" />
        <BooleanInput
          label="Allow export"
          source="allowExport"
          defaultValue={false}
        />
        <FileInput accept="text/csv" source="file" label="CSV file">
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  )
}

export const OwnDataEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm validate={validate(updateSchema)} toolbar={<CustomToolbar />}>
        <TextInput disabled source="id" />
        <TextInput source="name" />
        <TextInput source="description" />
        <DateInput source="startDate" />
        <DateInput source="endDate" />
        <BooleanInput label="Allow export" source="allowExport" />
        <ReferenceField label="Lataaja" source="uploadedBy" reference="user">
          <TextField source="email" emptyText="Ei lataajatietoa" />
        </ReferenceField>
        <ArrayInput source="segments">
          <SimpleFormIterator disableAdd disableRemove disableReordering>
            <TextInput label="Segmentti" source="name" />
          </SimpleFormIterator>
        </ArrayInput>

        <FileInput accept="text/csv" source="file" label="CSV-tiedosto">
          <FileField source="src" title="title" />
        </FileInput>
        <h5>
          Huom. Jos lisäät päivitykseen mukaan tiedoston, mahdolliset
          segmenttinimien muutokset eivät vaikuta. Voit muuttaa segmenttinimiä
          erikseen, jos et lataa samalla mukana uutta tiedostoa.
        </h5>

        <h3>Projektit</h3>
        <List
          resource="project"
          filter={{ ownDataId: props.id }}
          perPage={100}
          actions={<></>}
          pagination={<></>}
          bulkActionButtons={false}
        >
          <Datagrid>
            <TextField source="name" />
          </Datagrid>
        </List>
      </SimpleForm>
    </Edit>
  )
}

const postFilters = [
  <TextInput key="search" label="Nimi" source="name" alwaysOn />
]

export const OwnDataList: React.FC<ListProps> = (props) => (
  <List filters={postFilters} {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField label="Nimi" source="name" />
      <DateField label="Luotu" locales={'fi-FI'} source="createdOn" />
      <TextField label="Kuvaus" source="description" />
      <EditButton />
    </Datagrid>
  </List>
)
