import React, { FC } from 'react'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { UserAreaInstructions } from './Instructions'

import {
  SimpleForm,
  EditProps,
  Create,
  FileInput,
  FileField,
  Toolbar,
  ToolbarProps,
  SaveButton,
  required,
  Title,
  BooleanInput,
  TextInput
} from 'react-admin'

import '../../styles/table.css'

export const AddUserAreas: FC<EditProps> = (props) => {
  const AreaSaveToolbar: FC<ToolbarProps> = (props) => (
    // @ts-ignore
    <Toolbar {...props}>
      <SaveButton label="Luo uusi aluevalinta" submitOnEnter={false} />
    </Toolbar>
  )

  return (
    <Card>
      <Title title="Lisää käyttäjäalueita - " />
      <CardContent>
        <h2>Uuden aluevalinnan lisääminen</h2>
        <Create {...props} basePath="add-user-area">
          <SimpleForm toolbar={<AreaSaveToolbar />}>
            <TextInput
              source="name"
              label="Aluevalinnan nimi"
              helperText="Käyttäliittymässä näkyvä nimi"
            />
            <TextInput
              source="description"
              label="Aluevalinnan kuvaus"
              helperText="Kuvaus aluevalinnasta"
            />
            <BooleanInput
              helperText="Näkyykö aluevalinta kaikille?"
              source="common"
              label="Julkinen"
              defaultValue={false}
            />
            <FileInput
              validate={required({
                message: 'Sinun on lisättävä tiedosto päivitystä varten.'
              })}
              accept="text/csv"
              source="file"
              label="Alue-CSV tiedosto"
            >
              <FileField source="src" title="title" />
            </FileInput>
          </SimpleForm>
        </Create>
      </CardContent>
      <UserAreaInstructions />
    </Card>
  )
}
