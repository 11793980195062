import * as Yup from 'yup'

import { validate } from './validate'

const createSchemaNumberInterval = Yup.object().shape({
  label: Yup.string().required(),
  type: Yup.string().required(),
  studyId: Yup.string().required(),
  file: Yup.object().required(),
  scale: Yup.object()
    .shape({
      start: Yup.string().required(),
      end: Yup.string().required(),
      startLabel: Yup.string().required(),
      endLabel: Yup.string().required()
    })
    .required()
})

const createSchemaTwoOptions = createSchemaNumberInterval.shape({
  scale: Yup.object().nullable()
})

const updateSchemaNumberInterval = createSchemaNumberInterval.shape({
  file: Yup.object().nullable()
})

const updateSchemaTwoOptions = createSchemaTwoOptions.shape({
  file: Yup.object().nullable(),
  scale: Yup.object().nullable()
})

export const validateCreate = (values) => {
  if (
    values.type === 'number_interval' ||
    values.type === 'number_interval_with_respondent_id'
  ) {
    return validate(createSchemaNumberInterval)(values)
  } else {
    return validate(createSchemaTwoOptions)(values)
  }
}

export const validateUpdate = (values) => {
  if (values.type === 'number_interval') {
    return validate(updateSchemaNumberInterval)(values)
  } else {
    return validate(updateSchemaTwoOptions)(values)
  }
}
