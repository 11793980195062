import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  SelectArrayInput,
  ReferenceArrayInput,
  CreateProps
} from 'react-admin'
import { validate } from '../../../schema/validate'
import { PublicInput, schema, UserReference } from './publicInput'

export const ProjectCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props}>
      <SimpleForm validate={validate(schema)}>
        <TextInput source="name" />
        <TextInput multiline source="description" />
        <BooleanInput source="active" defaultValue={false} />
        <PublicInput source="public" />
        {props.permissions.role === 'superadmin' ? (
          <>
            <ReferenceInput
              source="customerId"
              reference="customer"
              perPage={500}
              sort={{ field: 'name', order: 'ASC' }}
            >
              <SelectInput fullWidth optionText="name" />
            </ReferenceInput>
            <ReferenceArrayInput
              label="Studies"
              source="studyIds"
              reference="study"
              defaultValue={[]}
              sort={{ field: 'name', order: 'ASC' }}
              perPage={500}
            >
              <SelectArrayInput fullWidth optionText="name" />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              label="OwnDatas"
              source="ownDataIds"
              reference="ownData"
              defaultValue={[]}
              sort={{ field: 'name', order: 'ASC' }}
              perPage={500}
            >
              <SelectArrayInput fullWidth optionText="name" />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              label="Show questions from studies"
              source="questionStudyIds"
              reference="study"
              defaultValue={[]}
              perPage={500}
              sort={{ field: 'name', order: 'ASC' }}
            >
              <SelectArrayInput fullWidth optionText="name" />
            </ReferenceArrayInput>
          </>
        ) : (
          <></>
        )}
        <UserReference
          label="Users"
          source="userIds"
          reference="user"
          defaultValue={[]}
          sort={{ field: 'email', order: 'ASC' }}
        />
      </SimpleForm>
    </Create>
  )
}
