import gql from 'graphql-tag'
import {
  customerFragment,
  projectFragment,
  studyFragment,
  userFragment,
  questionFragment,
  ownDataFragment,
  areaPopulationFragment
} from './fragments'

export const customersQuery = gql`
  query ($filter: CustomerFilter) {
    customers(filter: $filter) {
      total
      data {
        ...Customer
      }
    }
  }
  ${customerFragment}
`

export const customerQuery = gql`
  query ($id: ID!) {
    customer(id: $id) {
      ...Customer
    }
  }
  ${customerFragment}
`

export const projectsQuery = gql`
  query ($filter: ProjectFilter) {
    projects(filter: $filter) {
      total
      data {
        ...Project
      }
    }
  }
  ${projectFragment}
`

export const projectQuery = gql`
  query ($id: ID!) {
    project(id: $id) {
      ...Project
    }
  }
  ${projectFragment}
`

export const studiesQuery = gql`
  query ($filter: StudiesFilter!) {
    studies(filter: $filter) {
      total
      data {
        ...Study
      }
    }
  }
  ${studyFragment}
`

export const currentUserQuery = gql`
  query {
    currentUser {
      ...User
    }
  }
  ${userFragment}
`

export const usersQuery = gql`
  query ($filter: UserFilter) {
    users(filter: $filter) {
      total
      data {
        ...User
      }
    }
  }
  ${userFragment}
`

export const userQuery = gql`
  query ($id: ID!) {
    user(id: $id) {
      ...User
    }
  }
  ${userFragment}
`

export const questionsQuery = gql`
  query ($filter: QuestionFilter) {
    questions(filter: $filter) {
      total
      data {
        ...Question
      }
    }
  }
  ${questionFragment}
`

export const questionQuery = gql`
  query ($id: ID!) {
    question(id: $id) {
      ...Question
    }
  }
  ${questionFragment}
`

export const studyQuery = gql`
  query ($id: ID!) {
    study(id: $id) {
      ...Study
    }
  }
  ${studyFragment}
`

export const ownDatasQuery = gql`
  query ($filter: OwnDatasFilter!) {
    ownDatas(filter: $filter) {
      total
      data {
        ...OwnData
      }
    }
  }
  ${ownDataFragment}
`

export const ownDataQuery = gql`
  query ($id: ID!) {
    ownData(id: $id) {
      ...OwnData
    }
  }
  ${ownDataFragment}
`

export const areaPopulationQuery = gql`
  query ($id: ID!) {
    areaPopulation(id: $id) {
      ...Area
    }
  }
  ${areaPopulationFragment}
`

export const areaPopulationsQuery = gql`
  query ($filter: AreaPopulationFilter!) {
    areaPopulations(filter: $filter) {
      total
      data {
        ...Area
      }
    }
  }
  ${areaPopulationFragment}
`

export const areaSelectionQuery = gql`
  query ($id: ID!) {
    areaSelection(id: $id) {
      id
      name
      description
      common
      regions
      municipalities
      postals
    }
  }
`

export const areaSelectionsQuery = gql`
  query ($filter: AreaSelectionFilter!) {
    areaSelectionFeed(filter: $filter) {
      total
      data {
        id
        name
        description
        common
      }
    }
  }
`
