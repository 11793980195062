import { UserCreate } from './Create'
import { UserEdit } from './Edit'
import { UserList } from './List'

const roles = [
  { id: 'user', name: 'User' },
  { id: 'admin', name: 'Admin' }
]
const rolesSuperadmin = [...roles, { id: 'superadmin', name: 'Superadmin' }]

export { UserCreate, roles, rolesSuperadmin, UserList, UserEdit }
