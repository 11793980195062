import { useState, useEffect } from 'react'
import buildGraphQLProvider from 'ra-data-graphql'
import { Admin, Resource, DataProvider } from 'react-admin'
import finnishMessages from 'ra-language-finnish'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import ProjectIcon from '@material-ui/icons/Equalizer'
import CustomerIcon from '@material-ui/icons/Folder'
import UserIcon from '@material-ui/icons/AccountBox'
import QuestionIcon from '@material-ui/icons/QuestionAnswer'
import MapIcon from '@material-ui/icons/Map'
import StudyIcon from '@material-ui/icons/Assignment'
import WorkIcon from '@material-ui/icons/Work'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

import buildQuery from '../providers/data/buildQuery'
import { CustomerCreate, CustomerEdit, CustomerList } from './views/customer'
import { ProjectCreate, ProjectList, ProjectEdit } from './views/project'
import { UserCreate, UserList, UserEdit } from './views/user'
import { QuestionList, QuestionCreate, QuestionEdit } from './views/question'
import { StudyList, StudyCreate, StudyEdit } from './views/study'
import { AreaEdit, AreaList, AreaUpdate } from './views/area'
import {
  AddUserAreas,
  AreaSelectionList,
  EditUserAreas
} from './views/area-selection'
import { OwnDataList, OwnDataCreate, OwnDataEdit } from './views/ownData'
import customRoutes from './navigation/customRoutes'
import { authProvider } from '../providers/auth'
import NavLayout from './navigation/NavLayout'
import apolloClient from '../providers/data/apollo-client'
import __schema from '../graphql-schema.json'

const InsightBlue = '#2196F3'

const useStyles = makeStyles({
  loadingArea: {
    marginTop: '25vh',
    textAlign: 'center'
  },
  loadingText: {
    marginTop: '1em',
    fontSize: '1.3em'
  },
  loadingPlaceholderTopBar: {
    width: '100%',
    backgroundColor: InsightBlue,
    height: '4em'
  }
})

function App(): JSX.Element {
  const [dataProvider, setData] = useState<DataProvider | null>(null)
  const classes = useStyles()

  const createProvider = async () => {
    const provider = await buildGraphQLProvider({
      client: apolloClient,
      // @ts-ignore
      introspection: { schema: __schema.__schema },
      buildQuery
    })
    setData(provider)
  }

  useEffect(() => {
    createProvider()
  }, [])

  if (!dataProvider) {
    return (
      <>
        <div className={classes.loadingPlaceholderTopBar}></div>
        <div className={classes.loadingArea}>
          <CircularProgress color={'primary'} size="5em" />
          <div className={classes.loadingText}>Hallintapaneelia avataan...</div>
        </div>
      </>
    )
  }

  const i18nProvider = polyglotI18nProvider(() => finnishMessages, 'fi', {
    allowMissing: true
  })
  return (
    <Admin
      i18nProvider={i18nProvider}
      customRoutes={customRoutes}
      layout={NavLayout}
      dataProvider={dataProvider}
      authProvider={authProvider}
    >
      <Resource
        name="user"
        options={{ label: 'Käyttäjät' }}
        list={UserList}
        create={UserCreate}
        edit={UserEdit}
        icon={UserIcon}
      />
      <Resource
        name="areaPopulation"
        options={{ label: 'Alueet' }}
        create={AreaUpdate}
        list={AreaList}
        edit={AreaEdit}
        icon={MapIcon}
      />
      <Resource
        name="areaSelection"
        options={{ label: 'Aluevalinnat' }}
        create={AddUserAreas}
        edit={EditUserAreas}
        list={AreaSelectionList}
        icon={LocationOnIcon}
      />
      <Resource
        name="customer"
        options={{ label: 'Asiakkaat' }}
        list={CustomerList}
        create={CustomerCreate}
        edit={CustomerEdit}
        icon={CustomerIcon}
      />
      <Resource
        name="question"
        options={{ label: 'Kysymykset' }}
        list={QuestionList}
        create={QuestionCreate}
        edit={QuestionEdit}
        icon={QuestionIcon}
      />

      <Resource
        name="ownData"
        options={{ label: 'Oma datani' }}
        list={OwnDataList}
        create={OwnDataCreate}
        edit={OwnDataEdit}
        icon={WorkIcon}
      />
      <Resource
        name="project"
        options={{ label: 'Projektit' }}
        list={ProjectList}
        create={ProjectCreate}
        edit={ProjectEdit}
        icon={ProjectIcon}
      />
      <Resource
        name="study"
        options={{ label: 'Tutkimukset' }}
        list={StudyList}
        create={StudyCreate}
        edit={StudyEdit}
        icon={StudyIcon}
      />
    </Admin>
  )
}

export default App
