import { createRoot } from 'react-dom/client'
import './index.css'
import App from './components/App'

function render(Component: React.FunctionComponent<any>) {
  const container = document.getElementById('root')
  const root = createRoot(container!)
  root.render(<Component />)
}

render(App)
