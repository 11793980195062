import React from 'react'
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  List,
  Datagrid,
  TextField,
  EditButton,
  BooleanInput,
  BooleanField,
  CreateProps,
  EditProps,
  ListProps
} from 'react-admin'
import * as Yup from 'yup'

import { validate } from '../../schema/validate'
import { CustomToolbar } from './CustomToolbar'

const schema = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string().nullable(),
  active: Yup.boolean().required(),
  two_factor_authentication: Yup.boolean().nullable()
})

export const CustomerCreate: React.FC<CreateProps> = (props) => (
  <Create {...props}>
    <SimpleForm validate={validate(schema)} toolbar={<CustomToolbar />}>
      <TextInput source="name" />
      <TextInput source="description" />
      <BooleanInput source="active" defaultValue={false} />
      <BooleanInput source="two_factor_authentication" defaultValue={false} />
    </SimpleForm>
  </Create>
)

export const CustomerEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm validate={validate(schema)}>
        <TextInput source="name" />
        <TextInput source="description" />
        <BooleanInput source="active" />
        <BooleanInput source="two_factor_authentication" defaultValue={false} />
        <h3>Projektit</h3>
        <List
          resource="project"
          filter={{ customerId: props.id }}
          perPage={100}
          actions={<></>}
          pagination={<></>}
          bulkActionButtons={false}
        >
          <Datagrid
            resource="project"
            rowClick={(id) => {
              return `/project/${id}`
            }}
          >
            <TextField resource="project" source="name" />
          </Datagrid>
        </List>
      </SimpleForm>
    </Edit>
  )
}

export const CustomerList: React.FC<ListProps> = (props) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="description" />
      <BooleanField source="active" />
      <BooleanField source="two_factor_authentication" />
      <EditButton />
    </Datagrid>
  </List>
)
