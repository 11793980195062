import React, { FC } from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  EmailField,
  BooleanField,
  ReferenceField,
  TopToolbar,
  ExportButton,
  CreateButton,
  Filter,
  SearchInput,
  ListProps,
  EditActionsProps,
  DateField
} from 'react-admin'

import Button from '@material-ui/core/Button'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import { useHistory } from 'react-router-dom'
import { commonStyles } from '../../styles'

const PostEditActions: FC<EditActionsProps> = ({ basePath, data }) => {
  const history = useHistory()
  const classes = commonStyles()

  function redirectToMassUsers() {
    history.push('/massUsers')
  }

  return (
    <TopToolbar className={classes.toolbar}>
      <Filter>
        <SearchInput
          source="nameOrEmail"
          placeholder="Nimi tai sähköposti"
          alwaysOn
        />
      </Filter>
      <div>
        <CreateButton basePath={basePath} record={data} />
        <Button color="primary" onClick={redirectToMassUsers}>
          <GroupAddIcon />
          Luo paljon käyttäjiä
        </Button>
        <ExportButton basePath={basePath} record={data} />
      </div>
    </TopToolbar>
  )
}

export const UserList: FC<ListProps> = (props) => (
  <List
    actions={<PostEditActions {...props} />}
    {...props}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="edit">
      <TextField source="firstName" />
      <TextField source="lastName" />
      <EmailField source="email" />
      <TextField source="role" />
      <DateField source="createdOn" />
      <BooleanField source="active" />
      <ReferenceField label="Customer" source="customerId" reference="customer">
        <TextField source="name" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
)
