import {
  removeCustomerMutation,
  removeProjectMutation,
  removeUserMutation,
  removeQuestionMutation,
  removeStudyMutation,
  removeOwnDataMutation,
  areaSelectionDelete
} from '../graphql/mutations'

export default {
  customer: ({ id }) => ({
    query: removeCustomerMutation,
    variables: { id },
    parseResponse: (response) => ({ data: response.data.deleteCustomer })
  }),
  project: ({ id }) => ({
    query: removeProjectMutation,
    variables: { id },
    parseResponse: (response) => ({ data: response.data.deleteProject })
  }),
  user: ({ id }) => ({
    query: removeUserMutation,
    variables: { id },
    parseResponse: (response) => ({ data: response.data.deleteUser })
  }),
  question: ({ id }) => ({
    query: removeQuestionMutation,
    variables: { id },
    parseResponse: (response) => ({ data: response.data.deleteQuestion })
  }),
  study: ({ id }) => ({
    query: removeStudyMutation,
    variables: { id },
    parseResponse: (response) => ({ data: response.data.deleteStudy })
  }),
  ownData: ({ id }) => ({
    query: removeOwnDataMutation,
    variables: { id },
    parseResponse: (response) => ({ data: response.data.deleteOwnData })
  }),
  areaSelection: ({ id }) => ({
    query: areaSelectionDelete,
    variables: { id },
    parseResponse: (response) => ({ data: response.data.areaSelectionDelete })
  })
}
