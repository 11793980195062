import React, { FC } from 'react'

import {
  Create,
  SimpleForm,
  TextInput,
  TextField,
  SelectInput,
  BooleanInput,
  ReferenceInput,
  useNotify,
  useRefresh,
  useRedirect,
  Toolbar,
  SaveButton,
  FormDataConsumer,
  CreateProps,
  ToolbarProps
} from 'react-admin'

import { validateUserCreation } from '../../../schema/user'
import { roles, rolesSuperadmin } from '.'

type UserSave = {
  data: {
    firstName: string
    lastName: string
  }
}

export const UserCreate: FC<CreateProps> = (props) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()

  const PostCreateToolbar: FC<ToolbarProps> = (props) => (
    // @ts-ignore
    <Toolbar {...props}>
      <SaveButton
        onSuccess={saveSuccess}
        label="Luo uusi käyttäjä"
        submitOnEnter={false}
      />
    </Toolbar>
  )

  const saveSuccess = ({ data }: UserSave) => {
    notify(
      `Käyttäjälle: ${data.firstName} ${data.lastName} on luotu onnistuneesti tunnus.`
    )
    redirect('/user')
    refresh()
  }

  return (
    <Create {...props}>
      <SimpleForm
        validate={validateUserCreation}
        toolbar={<PostCreateToolbar />}
      >
        <TextInput source="firstName" />
        <TextInput source="lastName" />
        <TextInput source="email" />
        <BooleanInput
          label="Asetan salasanan itse"
          source="createManualPassword"
          defaultValue={false}
        />
        <TextField label="Jos asetat salasanan itse, käyttäjälle ei lähetetä automaattista kirjautumissähköpostia." />
        <FormDataConsumer>
          {({ formData }) => (
            <>
              {formData.createManualPassword ? (
                <TextInput
                  style={{ width: '16em' }}
                  source="password"
                  type="password"
                />
              ) : null}
            </>
          )}
        </FormDataConsumer>
        <SelectInput
          source="role"
          choices={
            props.permissions.role === 'superadmin' ? rolesSuperadmin : roles
          }
          defaultValue="user"
        />
        <BooleanInput source="active" defaultValue={true} />

        {props.permissions.role === 'superadmin' ? (
          <ReferenceInput
            source="customerId"
            reference="customer"
            label="Customer"
            perPage={500}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        ) : (
          <></>
        )}
      </SimpleForm>
    </Create>
  )
}
