import {
  customersQuery,
  projectsQuery,
  studiesQuery,
  usersQuery,
  questionsQuery,
  ownDatasQuery,
  areaPopulationsQuery,
  areaSelectionsQuery
} from '../graphql/queries'

function parseFilters(filters) {
  const { pagination, filter, sort, ...rest } = filters
  const parsedFilter = { ...pagination, ...filter, ...rest }
  if (sort) {
    parsedFilter['sort'] = {
      sort: sort.field,
      order: sort.order
    }
  }
  return parsedFilter
}

export default {
  customer: (filters) => ({
    query: customersQuery,
    variables: { filter: parseFilters(filters) },
    parseResponse: (response) => response.data.customers
  }),
  project: (filter) => ({
    query: projectsQuery,
    variables: { filter: parseFilters(filter) },
    parseResponse: (response) => response.data.projects
  }),
  user: (filters) => ({
    query: usersQuery,
    variables: { filter: parseFilters(filters) },
    parseResponse: (response) => response.data.users
  }),
  study: (filters) => ({
    query: studiesQuery,
    variables: { filter: parseFilters(filters) },
    parseResponse: (response) => response.data.studies
  }),
  question: (filters) => ({
    query: questionsQuery,
    variables: { filter: parseFilters(filters) },
    parseResponse: (response) => response.data.questions
  }),
  ownData: (filters) => ({
    query: ownDatasQuery,
    variables: {
      filter: parseFilters(filters)
    },
    parseResponse: (response) => response.data.ownDatas
  }),
  areaPopulation: ({ pagination, filter }) => ({
    query: areaPopulationsQuery,
    variables: { filter: { ...pagination, ...filter } },
    parseResponse: (response) => response.data.areaPopulations
  }),
  areaSelection: ({ pagination, filter }) => ({
    query: areaSelectionsQuery,
    variables: { filter: { ...pagination, ...filter } },
    parseResponse: (response) => response.data.areaSelectionFeed
  })
}
