import { list, create, update, remove, one } from '../../operations'

const buildQuery =
  (introspectionResults) => (raFetchType, resourceName, params) => {
    switch (raFetchType) {
      case 'CREATE':
        return create[resourceName](params)
      case 'UPDATE':
        return update[resourceName](params)
      case 'DELETE':
        return remove[resourceName](params)
      case 'GET_LIST':
        return list[resourceName](params)
      case 'GET_ONE':
        return one[resourceName](params)
      case 'GET_MANY':
        return list[resourceName](params)
      default:
        throw Error(`Operation type '${raFetchType}' not supported yet`)
    }
  }

export default buildQuery
