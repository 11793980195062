import React, { FC } from 'react'

import {
  SimpleForm,
  EditProps,
  Create,
  FileInput,
  FileField,
  useNotify,
  useRefresh,
  useRedirect,
  Toolbar,
  ToolbarProps,
  SaveButton,
  required
} from 'react-admin'

type SaveData = {
  data: {
    updateCount: number
    status: string
  }
}

export const AreaUpdate: FC<EditProps> = (props) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()

  const AreaSaveToolbar: FC<ToolbarProps> = (props) => (
    // @ts-ignore
    <Toolbar {...props}>
      <SaveButton
        onSuccess={saveSuccess}
        label="Päivitä Paavo-data"
        submitOnEnter={false}
      />
    </Toolbar>
  )

  const saveSuccess = ({ data: { updateCount } }: SaveData) => {
    notify(`${updateCount} aluetta päivitettiin onnistuneesti tietokantaan`)
    redirect('/areaPopulation')
    refresh()
  }

  return (
    <div>
      <h1>Paavo-datan päivitys</h1>
      <p>
        Paavo-datasta päivitetään vain postinumeroalueittain saatava osuus.
        Paavo-data olisi tarjolla myös kunnittain ja maakunnittain, mutta
        omaData ja tutkimusominaisuuksien vuoksi, data yhteenlasketaan
        pelkästään postinumeroista.
      </p>
      <p>Paavo-datan voi ladata Tilastokeskuksen PxWeb-palvelusta.</p>
      <p>
        Paavo-aineisto päivitetään vuosittain ja tulisi myös päivittää
        vuosittain
      </p>
      <p>
        Päivitys tapahtuu lataamalla ensin Paavo-aineisto kaikille
        postinumeroille ja kaikilla tietoryhmän muuttujilla{' '}
        <span style={{ fontWeight: 'bold' }}>Json file (json)</span> -muodossa,
        ei CSV-muodossa.
      </p>
      <p>
        Kun aineisto on ladattu tietokoneellesi PxWebistä. Voit ladata sen
        tietokantaan käyttämällä allaolevaa latauskenttää.
      </p>
      <p>
        Huom! JSON-tiedosto on liian suuri päivitettäväksi kerralla ja antaa
        Paavo-dataa päivittäessä virheen. Tiedosto pitää jakaa useampiin (alle
        1mb) kokoisiin osiin. Helpoiten tämä onnistuu ottamalla noin kolmannes
        postinumerodatasta kerralla ja jakaa tiedot kolmeen erilliseen
        JSON-tiedostoon.
      </p>
      <Create {...props}>
        <SimpleForm toolbar={<AreaSaveToolbar />}>
          <FileInput
            validate={required({
              message: 'Sinun on lisättävä tiedosto päivitystä varten.'
            })}
            accept="application/json"
            source="file"
            label="Paavo JSON-file"
          >
            <FileField source="src" title="title" />
          </FileInput>
        </SimpleForm>
      </Create>
    </div>
  )
}
