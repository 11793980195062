import {
  BooleanInput,
  FormDataConsumer,
  SelectArrayInput,
  ReferenceArrayInput
} from 'react-admin'
import * as Yup from 'yup'

import get from 'lodash/get'

import { SERVICE_URL } from '../../../config'

export const schema = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string().nullable(),
  customerId: Yup.string().required(),
  active: Yup.boolean().required(),
  public: Yup.boolean().required(),
  studyIds: Yup.array().of(Yup.string()).nullable(),
  ownDataIds: Yup.array().of(Yup.string()).nullable(),
  userIds: Yup.array().of(Yup.string()).nullable()
})

// @ts-ignore
export const UserReference = (props) => {
  const customerId = get(props, 'record.customerId')
  return (
    <ReferenceArrayInput {...props} filter={{ customerId }} perPage={500}>
      <SelectArrayInput optionText="email" />
    </ReferenceArrayInput>
  )
}

export const PublicInput = ({ ...props }) => (
  <FormDataConsumer>
    {({ formData, ...rest }) => {
      const { id, public: publ } = formData || {}
      const link = `${SERVICE_URL}/public/${id}`
      return (
        <>
          <BooleanInput
            label="Julkinen"
            source="public"
            {...props}
            {...rest}
            defaultValue={false}
          />
          {id && publ && (
            <div>
              Julkinen linkki:&nbsp;
              <a href={link} rel="noopener noreferrer" target="_blank">
                {link}
              </a>
            </div>
          )}
        </>
      )
    }}
  </FormDataConsumer>
)
