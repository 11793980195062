import React from 'react'

export function UserAreaInstructions(): JSX.Element {
  return (
    <div style={{ padding: '2em', borderBottom: '3px solid black' }}>
      <h3>Esimerkkitiedosto</h3>
      <table id="customers">
        <thead>
          <tr>
            <th>Aluetyyppi</th>
            <th>Aluekoodi</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Postinumero</td>
            <td>00970</td>
          </tr>
          <tr>
            <td>Postinumero</td>
            <td>00980</td>
          </tr>
          <tr>
            <td>Postinumero</td>
            <td>00990</td>
          </tr>
          <tr>
            <td>Kunta</td>
            <td>091</td>
          </tr>
          <tr>
            <td>Maakunta</td>
            <td>03</td>
          </tr>
        </tbody>
      </table>
      <p>
        Ladattavan tiedoston on vastattava tämän esimerkin muotoa, mukaan lukien
        ylhäällä olevat sarakenimet.
      </p>
      <p>
        Tietoja täytetään rivettäin. Ensimmäiseksi tulee alueen tyyppi, sen
        jälkeen aluekoodi.
      </p>
      <p>
        Aluetyyppejä on kolmea erilaista. Postinumero, Maakunta ja Kunta.
        Aluetyyppi on oltava kirjoitettu oikein.
      </p>
      <p>
        Postinumerokoodi on aina 5-, kuntanumero 3-, ja maakunta 2-numeroa
        pitkä.
      </p>
      <p>
        Kaikki lisätyn kunnan tai maakunnan postinumerot lisätään mukaan
        alueeseen.
      </p>
      <p>
        Tiedostossa voi olla esimerkiksi lisättynä postinumero ja lisättynä
        kunnan tai maakunnan mukana. Esimerkiksi Katajanokka, Helsinki ja
        Uusimaa samassa tiedostossa. Tällöin mukaan otetaan kaikki Uudenmaan
        postinumerot. Isompi alue ottaa aina prioriteetin.
      </p>
    </div>
  )
}
