import * as React from 'react'
import { Layout } from 'react-admin'
import NavigationBar from './NavigationBar'
import Menu from './Menu'

const NavLayout = (props) => (
  <Layout {...props} appBar={NavigationBar} menu={Menu} />
)

export default NavLayout
