import React, { FC } from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  EditProps
} from 'react-admin'

export const EditUserAreas: FC<EditProps> = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput
          source="name"
          label="Aluevalinnan nimi"
          helperText="Käyttäliittymässä näkyvä nimi"
        />
        <TextInput
          source="name"
          label="Aluevalinnan kuvaus"
          helperText="Kuvaus aluevalinnasta"
        />
        <BooleanInput
          helperText="Näkyykö aluevalinta kaikille?"
          source="common"
          label="Julkinen"
        />
      </SimpleForm>
    </Edit>
  )
}
